import React, { useEffect, useRef } from "react";
import classes from "./OurValues.module.scss";
import image_01 from "../../../assets/img/AboutPage/OurValues/image_01.webp";
import image_02 from "../../../assets/img/AboutPage/OurValues/image_02.webp";
import image_03 from "../../../assets/img/AboutPage/OurValues/image_03.webp";
import image_04 from "../../../assets/img/AboutPage/OurValues/image_04.webp";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const OurValues = () => {
  const { t } = useTranslation();
  const cardRef_01 = useRef(null);
  const cardRef_02 = useRef(null);
  const cardRef_03 = useRef(null);
  const cardRef_04 = useRef(null);
  const cards = [
    {
      ref: cardRef_01,
      image: image_01,
      title: "about.our_values.card_01.title",
      text: "about.our_values.card_01.text",
    },
    {
      ref: cardRef_02,
      image: image_02,
      title: "about.our_values.card_02.title",
      text: "about.our_values.card_02.text",
    },
    {
      ref: cardRef_03,
      image: image_03,
      title: "about.our_values.card_03.title",
      text: "about.our_values.card_03.text",
    },
    {
      ref: cardRef_04,
      image: image_04,
      title: "about.our_values.card_04.title",
      text: "about.our_values.card_04.text",
    },
  ];
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (document.documentElement.clientWidth > 1220) {
        gsap.timeline().fromTo(
          cardRef_01.current,
          {
            x: -100,
          },
          {
            x: 0,
            scrollTrigger: {
              trigger: cardRef_01.current,
              end: "+=200px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          cardRef_02.current,
          {
            x: 100,
          },
          {
            x: 0,
            scrollTrigger: {
              trigger: cardRef_01.current,
              end: "+=200px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          cardRef_03.current,
          {
            x: -100,
          },
          {
            x: 0,
            scrollTrigger: {
              trigger: cardRef_03.current,
              end: "+=200px",
              scrub: 3,
            },
          }
        );
        gsap.timeline().fromTo(
          cardRef_04.current,
          {
            x: 100,
          },
          {
            x: 0,
            scrollTrigger: {
              trigger: cardRef_03.current,
              end: "+=200px",
              scrub: 3,
            },
          }
        );
      }
    }
  }, []);
  return (
    <section className="container mt mb">
      <div className={classes.ourValues}>
        <h2 className={[classes.title, "font-48"].join(" ")}>
          {t('about.our_values.title')}
        </h2>
        <div className={classes.cards}>
          {cards.map((card, index) => (
            <div ref={card.ref} key={index} className={classes.card}>
              <img
                className={classes.cardImage}
                src={card.image}
                alt={card.title}
              />
              <h4 className={[classes.cardTitle, "font-32"].join(" ")}>
                {t(card.title)}
              </h4>
              <p className={[classes.cardText, "font-20"].join(" ")}>
                {t(card.text)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurValues;
