import React, { useEffect, useState } from "react";
import classes from "./Second.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import img1 from "../../../assets/img/trading/2/1.webp";
import img2 from "../../../assets/img/trading/2/2.webp";
import img3 from "../../../assets/img/trading/2/3.webp";
import img4 from "../../../assets/img/trading/2/4.webp";
import img5 from "../../../assets/img/trading/2/5.webp";
import arrow from "../../../assets/img/trading/2/arrow.png";
import { useTranslation } from "react-i18next";

const content = [
  {
    id: 1,
    img: img1,
    title: "conditions.details.card_01.title",
    text: "conditions.details.card_01.text",
  },
  {
    id: 2,
    img: img2,
    title: "conditions.details.card_02.title",
    text: "conditions.details.card_02.text",
  },
  {
    id: 3,
    img: img3,
    title: "conditions.details.card_03.title",
    text: "conditions.details.card_03.text",
  },
  {
    id: 4,
    img: img4,
    title: "conditions.details.card_04.title",
    text: "conditions.details.card_04.text",
  },
  {
    id: 5,
    img: img5,
    title: "conditions.details.card_05.title",
    text: "conditions.details.card_05.text",
  },
  {
    id: 6,
    opacity1: true,
  },
  {
    id: 7,
    opacity2: true,
  },
];

const Second = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  const [my_swiper, set_my_swiper] = useState({});
  return (
    <div className={classes.second}>
      <div className={classes.secondBody}>
        <div className="container">
          <h2 className={[classes.secondTitle, "font-24"].join(" ")}>
            {t('conditions.details.title')}
          </h2>
          <div className={classes.secondSlider}>
            <Swiper
              onInit={(ev) => set_my_swiper(ev)}
              slidesPerView={4}
              spaceBetween={20}
              breakpoints={{
                801: {
                  slidesPerView: 4,
                },
                651: {
                  slidesPerView: 2,
                },
                100: {
                  slidesPerView: 1,
                },
              }}
            >
              {content.map((i, index) => {
                if (i.opacity1) {
                  if (width < 1651) {
                    if (width < 801) return null;
                    return (
                      <SwiperSlide key={index}>
                        <div className={classes.slide}>
                          <div className={[classes.slideItem, classes.opacity].join(" ")}></div>
                        </div>
                      </SwiperSlide>
                    );
                  } else {
                    return null;
                  }
                }
                if (i.opacity2) {
                  if (width < 1170) {
                    if (width < 801) return null;
                    return (
                      <SwiperSlide key={index}>
                        <div className={classes.slide}>
                          <div className={[classes.slideItem, classes.opacity].join(" ")}></div>
                        </div>
                      </SwiperSlide>
                    );
                  } else {
                    return null;
                  }
                }

                return (
                  <SwiperSlide key={index}>
                    <div className={classes.slide}>
                      <div className={classes.slideItem}>
                        <h3 className={[classes.slideItemTitle, "font-24"].join(" ")}>
                          {t(i.title)}
                        </h3>
                        <div className={classes.slideItemBg}>
                          <img alt="Details" src={i.img} />
                        </div>
                        <p className={[classes.slideItemText, "font-20"].join(" ")}>
                          {t(i.text)}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className={classes.secondWrappperArrows}>
            <div className={classes.secondArrows}>
              <div
                className={classes.secondArrowsLeft}
                onClick={() => my_swiper.slidePrev()}
              >
                <img alt={"Details"} src={arrow} />
              </div>
              <div
                className={classes.secondArrowsRight}
                onClick={() => my_swiper.slideNext()}
              >
                <img alt={"Details"} src={arrow} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
