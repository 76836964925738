import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from '../components/HomePage/Banner/Banner';
import OurSecurity from '../components/HomePage/OurSecurity/OurSecurity';
import QuickInfo from '../components/HomePage/QuickInfo/QuickInfo';
import WhyChoose from '../components/HomePage/WhyChoose/WhyChoose';
import ChooseYour from '../components/HomePage/ChooseYour/ChooseYour';
import MeetOur from '../components/HomePage/MeetOur/MeetOur';
import TheSupport from '../components/HomePage/TheSupport/TheSupport';

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers L.P.</title>
        <meta name="title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />
        <meta property="og:title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />
        <meta property="twitter:title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />

        <meta name="description" content="BGC Brokers L.P. offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />
        <meta property="og:description" content="BGC Brokers L.P. offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />
        <meta property="twitter:description" content="BGC Brokers L.P. offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <OurSecurity />
        <QuickInfo />
        <WhyChoose />
        <ChooseYour />
        <MeetOur />
        <TheSupport />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
