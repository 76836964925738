import React, { useEffect, useRef } from "react";
import classes from './WhyChoose.module.scss';
import image from '../../../assets/img/HomePage/WhyChoose/image.webp';
import icon_01 from '../../../assets/img/HomePage/WhyChoose/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/WhyChoose/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/WhyChoose/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/WhyChoose/icon_04.svg';
import icon_05 from '../../../assets/img/HomePage/WhyChoose/icon_05.svg';
import icon_06 from '../../../assets/img/HomePage/WhyChoose/icon_06.svg';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const cards = [
    {
        icon: icon_01,
        text: 'home.why_choose.li_01',
    },
    {
        icon: icon_02,
        text: 'home.why_choose.li_02',
    },
    {
        icon: icon_03,
        text: 'home.why_choose.li_03',
    },
    {
        icon: icon_04,
        text: 'home.why_choose.li_04',
    },
    {
        icon: icon_05,
        text: 'home.why_choose.li_05',
    },
    {
        icon: icon_06,
        text: 'home.why_choose.li_06',
    },
];

const WhyChoose = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(imageRef.current, {
                    scale: 0.8,
                    y: 100,
                }, {
                    scale: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={[classes.whyChoose, 'mt'].join(' ')}>
            <div className="container">
                <div ref={bodyRef} className={classes.whyChooseBody}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-48'].join(' ')}>
                            {t('home.why_choose.title')}
                        </h2>
                        <div className={classes.cards}>
                            {cards.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <img className={classes.cardIcon} src={card.icon}  alt='' />
                                    <p className={[classes.cardText, 'font-20'].join(' ')}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <img ref={imageRef} className={classes.image} src={image}  alt='' />
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;
