import React, { useEffect, useRef } from "react";
import classes from './OurSecurity.module.scss';
import light from '../../../assets/img/HomePage/OurSecurity/light.webp';
import image_01 from '../../../assets/img/HomePage/OurSecurity/image_01.webp';
import image_02 from '../../../assets/img/HomePage/OurSecurity/image_02.webp';
import image_03 from '../../../assets/img/HomePage/OurSecurity/image_03.webp';
import image_04 from '../../../assets/img/HomePage/OurSecurity/image_04.webp';
import info_icon from '../../../assets/img/HomePage/OurSecurity/info_icon.svg';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);


const OurSecurity = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            image: image_01,
            ref: cardRef_01,
            title: 'home.our_security.card_01.title',
            text: 'home.our_security.card_01.text',
        },
        {
            image: image_02,
            ref: cardRef_02,
            title: 'home.our_security.card_02.title',
            text: 'home.our_security.card_02.text',
        },
        {
            image: image_03,
            ref: cardRef_03,
            title: 'home.our_security.card_03.title',
            text: 'home.our_security.card_03.text',
        },
        {
            image: image_04,
            ref: cardRef_04,
            title: 'home.our_security.card_04.title',
            text: 'home.our_security.card_04.text',
        },
    ];
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(cardRef_01.current, {
                    x: -100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_01.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_02.current, {
                    x: 100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_02.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_03.current, {
                    x: -100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_03.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_04.current, {
                    x: 100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_04.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={classes.ourSecurity}>
            <img className={classes.light} src={light} alt='' />
            <div className="container">
                <div className={classes.ourSecurityBody}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        {t('home.our_security.title')}
                    </h2>
                    <h4 className={[classes.subtitle, 'font-24'].join(' ')}>
                        {t('home.our_security.subtitle')}
                    </h4>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        <Trans>
                            {t('home.our_security.text')}
                        </Trans>
                    </p>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div ref={card.ref} key={index} className={classes.card}>
                                <img className={classes.cardImage} src={card.image} alt='' />
                                <h5 className={[classes.cardTitle, 'font-24'].join(' ')}>
                                    {t(card.title)}
                                </h5>
                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={classes.bottomCard}>
                        <img className={classes.bottomCardIcon} src={info_icon} alt=''/>
                        <p className={[classes.bottomCardText, 'font-20'].join(' ')}>
                            {t('home.our_security.desc')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurSecurity;
