import React, { useEffect } from "react";
import classes from './ChooseYour.module.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import image_01 from '../../../assets/img/HomePage/ChooseYour/image_01.webp';
import image_02 from '../../../assets/img/HomePage/ChooseYour/image_02.webp';
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ChooseYour = () => {
    const { t } = useTranslation();
    const { lang } = useParams();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                $(`#chooseYourCard_01`).hover(
                    () => {
                        $(`#chooseYourCard_01`).addClass(`${classes.card_01_hover}`);
                        $(`#chooseYourCard_02`).addClass(`${classes.card_01_hover_02}`);
                    },
                    () => {
                        $(`#chooseYourCard_01`).removeClass(`${classes.card_01_hover}`)
                        $(`#chooseYourCard_02`).removeClass(`${classes.card_01_hover_02}`);
                    },
                );
                $(`#chooseYourCard_02`).hover(
                    () => {
                        $(`#chooseYourCard_02`).addClass(`${classes.card_02_hover}`)
                    },
                    () => {
                        $(`#chooseYourCard_02`).removeClass(`${classes.card_02_hover}`)
                    },
                );
            }
        }
    }, []);
    return (
        <section className={[classes.chooseYour, 'mt'].join(' ')}>
            <div className="container">
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('home.choose_your.title')}
                </h2>
            </div>
            <div className={[classes.container, 'container'].join(' ')}>
                <div className={classes.cards}>
                    <div id="chooseYourCard_01" className={[classes.card, classes.card_01].join(' ')}>
                        <div className={classes.headerText}>
                            {t('home.choose_your.card_01.desc')}
                        </div>
                        <div className={classes.cardContent}>
                            <div className={classes.cardContentText}>
                                <h4 className={classes.cardTitle}>
                                    {t('home.choose_your.card_01.title')}
                                </h4>
                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                    {t('home.choose_your.card_01.text')}
                                </p>
                                <ul className="font-20">
                                    <li>
                                        {t('home.choose_your.card_01.li_01')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_01.li_02')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_01.li_03')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_01.li_04')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_01.li_05')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_01.li_06')}
                                    </li>
                                </ul>
                                <div className={classes.btn}>
                                    <a href={`${process.env.REACT_APP_CABINET_URL}/signup?lang=${lang}`} className="no-select">
                                        {t('btn.open_account')}
                                    </a>
                                </div>
                            </div>
                            <img className={classes.cardImage} src={image_01} alt='' />
                        </div>
                    </div>
                    <div id="chooseYourCard_02" className={[classes.card, classes.card_02].join(' ')}>
                        <div className={classes.headerText}>
                            {t('home.choose_your.card_02.desc')}
                        </div>
                        <div className={classes.cardContent}>
                            <div className={classes.cardContentText}>
                                <h4 className={classes.cardTitle}>
                                    {t('home.choose_your.card_02.title')}
                                </h4>
                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                    {t('home.choose_your.card_02.text')}
                                </p>
                                <ul className="font-20">
                                    <li>
                                        {t('home.choose_your.card_02.li_01')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_02.li_02')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_02.li_03')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_02.li_04')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_02.li_05')}
                                    </li>
                                    <li>
                                        {t('home.choose_your.card_02.li_06')}
                                    </li>
                                </ul>
                                <div className={classes.btn}>
                                    <a href={`${process.env.REACT_APP_CABINET_URL}/signup?lang=${lang}`} className="no-select">
                                        {t('btn.open_account')}
                                    </a>
                                </div>
                            </div>
                            <img className={classes.cardImage} src={image_02} alt='' />
                        </div>
                    </div>
                </div>
                <div className={classes.swiperWr}>
                    <Swiper
                        modules={[Pagination]}
                        className="chooseYourSwiper"
                        spaceBetween={20}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            1221: {
                                slidesPerView: 2,
                            },
                            480: {
                                spaceBetween: 40,
                            },
                        }}
                    >
                        <SwiperSlide className="chooseYourSwiperSlide">
                            <div className={classes.slide}>
                                <div className={[classes.card, classes.card_01].join(' ')}>
                                    <div className={classes.headerText}>
                                        {t('home.choose_your.card_01.desc')}
                                    </div>
                                    <div className={classes.cardContent}>
                                        <div className={classes.cardContentText}>
                                            <h4 className={classes.cardTitle}>
                                                {t('home.choose_your.card_01.title')}
                                            </h4>
                                            <p className={[classes.cardText, 'font-20'].join(' ')}>
                                                {t('home.choose_your.card_01.text')}
                                            </p>
                                            <ul className="font-20">
                                                <li>
                                                    {t('home.choose_your.card_01.li_01')}
                                                </li>
                                                <li>
                                                    {t('home.choose_your.card_01.li_02')}
                                                </li>
                                                <li>
                                                    {t('home.choose_your.card_01.li_03')}
                                                </li>
                                                <li>
                                                    {t('home.choose_your.card_01.li_04')}
                                                </li>
                                                <li>
                                                    {t('home.choose_your.card_01.li_05')}
                                                </li>
                                                <li>
                                                    {t('home.choose_your.card_01.li_06')}
                                                </li>
                                            </ul>
                                            <div className={classes.btn}>
                                                <a href={`${process.env.REACT_APP_CABINET_URL}/signup?lang=${lang}`} className="no-select">
                                                    {t('btn.open_account')}
                                                </a>
                                            </div>
                                        </div>
                                        <img className={classes.cardImage} src={image_01} alt='' />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="chooseYourSwiperSlide">
                            <div className={[classes.card, classes.card_02].join(' ')}>
                                <div className={classes.headerText}>
                                    {t('home.choose_your.card_02.desc')}
                                </div>
                                <div className={classes.cardContent}>
                                    <div className={classes.cardContentText}>
                                        <h4 className={classes.cardTitle}>
                                            {t('home.choose_your.card_02.title')}
                                        </h4>
                                        <p className={[classes.cardText, 'font-20'].join(' ')}>
                                            {t('home.choose_your.card_02.text')}
                                        </p>
                                        <ul className="font-20">
                                            <li>
                                                {t('home.choose_your.card_02.li_01')}
                                            </li>
                                            <li>
                                                {t('home.choose_your.card_02.li_02')}
                                            </li>
                                            <li>
                                                {t('home.choose_your.card_02.li_03')}
                                            </li>
                                            <li>
                                                {t('home.choose_your.card_02.li_04')}
                                            </li>
                                            <li>
                                                {t('home.choose_your.card_02.li_05')}
                                            </li>
                                            <li>
                                                {t('home.choose_your.card_02.li_06')}
                                            </li>
                                        </ul>
                                        <div className={classes.btn}>
                                            <a href={`${process.env.REACT_APP_CABINET_URL}/signup?lang=${lang}`} className="no-select">
                                                {t('btn.open_account')}
                                            </a>
                                        </div>
                                    </div>
                                    <img className={classes.cardImage} src={image_02} alt='' />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default ChooseYour;
