import React from "react";
import classes from './Table.module.scss';
import check from '../../../assets/img/PromotionsPage/Table/check.svg';
import minus from '../../../assets/img/PromotionsPage/Table/minus.svg';
import light from '../../../assets/img/PromotionsPage/Table/light.webp';
import { useTranslation } from "react-i18next";

const rows = [
    [
        'promotions.table.row_01',
        true,
        true,
        true,
    ],
    [
        'promotions.table.row_02',
        true,
        'promotions.table.without_verification',
        'promotions.table.without_verification',
    ],
    [
        'promotions.table.row_03',
        true,
        true,
        true,
    ],
    [
        'promotions.table.row_04',
        'promotions.table.first_deposit',
        'promotions.table.first_deposit',
        'promotions.table.first_deposit',
    ],
    [
        'promotions.table.row_05',
        false,
        false,
        true,
    ],
    [
        'promotions.table.row_06',
        true,
        true,
        true,
    ],
    [
        'promotions.table.row_07',
        '1:100',
        '1:100',
        '1:100',
    ],
    [
        'promotions.table.row_08',
        false,
        false,
        false,
    ],
    [
        'promotions.table.row_09',
        '100%',
        '100%',
        '100%',
    ],
];

const Table = () => {
    const { t } = useTranslation();
    return (
        <section className={[classes.tableBlock, 'mt'].join(' ')}>
            <img className={classes.light} src={light} alt='' />
            <div className={[classes.container, 'container'].join(' ')}>
                <div className={classes.tableWr}>
                    <table className="font-20">
                        <tbody>
                            <tr className={classes.headerRow}>
                                <td>
                                    {t('promotions.table.thead_01')}
                                </td>
                                <td>
                                    <div className={classes.td}>
                                        <span className={classes.tableTitle}>
                                            {t('promotions.table.thead_02.01')}
                                        </span>
                                        <span className={classes.tableDesc}>
                                            {t('promotions.table.thead_02.02')}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className={classes.td}>
                                        <span className={classes.tableTitle}>
                                            {t('promotions.table.thead_03.01')}
                                        </span>
                                        <span className={classes.tableDesc}>
                                            {t('promotions.table.thead_03.02')}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className={classes.td}>
                                        <span className={classes.tableTitle}>
                                            {t('promotions.table.thead_04.01')}
                                        </span>
                                        <span className={classes.tableDesc}>
                                            {t('promotions.table.thead_04.02')}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            {rows.map((row, rowIndex) => 
                                <tr key={rowIndex}>
                                    {row.map((col, colIndex) => {
                                        if (typeof(col) === 'string') {
                                            return (
                                                <td key={colIndex}>
                                                    {t(col)}
                                                </td>
                                            );
                                        } else if (col) {
                                            return (
                                                <td key={colIndex}>
                                                    <img className={classes.imgCheck} src={check} alt=''  />
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={colIndex}>
                                                    <img className={classes.imgMinus} src={minus} alt=''  />
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Table;
