import React, { useEffect, useRef } from "react";
import classes from './QuickInfo.module.scss';
import image from '../../../assets/img/HomePage/QuickInfo/image.webp';
import light from '../../../assets/img/HomePage/QuickInfo/light.webp';
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const QuickInfo = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(imageRef.current, {
                    scale: 0.7,
                    y: 100,
                }, {
                    scale: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={[classes.quickInfo, 'mt'].join(' ')}>
            <img className={classes.light} src={light} alt='' />
            <div className="container">
                <div ref={bodyRef} className={classes.quickInfoBody}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        {t('home.quick_info.title')}
                    </h2>
                    <ul className="font-20">
                        <li>
                            <Trans>
                                {t('home.quick_info.li_01')}
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                {t('home.quick_info.li_02')}
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                {t('home.quick_info.li_03')}
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                {t('home.quick_info.li_04')}
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                {t('home.quick_info.li_05')}
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                {t('home.quick_info.li_06')}
                            </Trans>
                        </li>
                    </ul>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.start_trading')}
                        </Button>
                    </div>
                    <img ref={imageRef} className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default QuickInfo;
