import React, { useEffect, useState } from "react";
import classes from "./Second.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import arrow from "../../../assets/img/trading/2/arrow.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const content = [
  {
    id: 1,
    title: "Standart",
    text: "accounts.card_01.text",
    item1: "EUR, USD, RUR",
    item2: "1 USD",
    item3: "accounts.no_limits",
    item4: "3-7",
    item5: "0",
    item6: "1:1 -1:1000",
    item7: "25%",
    item8: "30%",
    item9: "10%",
    item10: "accounts.instant",
    opacity: false,
  },
  {
    id: 2,
    title: "Eurica",
    text: "accounts.card_02.text",
    item1: "EUR, USD, RUR",
    item2: "1 USD",
    item3: "accounts.no_limits",
    item4: "0",
    item5: "0.03%-0.08%",
    item6: "1:1 -1:1000",
    item7: "25%",
    item8: "30%",
    item9: "10%",
    item10: "accounts.instant",
    opacity: false,
  },
  {
    id: 3,
    title: "Cent Standart",
    text: "accounts.card_03.text",
    item1: "USD cents, EUR cents",
    item2: "100 USD",
    item3: "1000 USD",
    item4: "3-7",
    item5: "0",
    item6: "1:1 -1:1000",
    item7: "25%",
    item8: "30%",
    item9: "10%",
    item10: "accounts.instant",
    opacity: false,
  },
  {
    id: 4,
    title: "Cent Eurica",
    text: "accounts.card_04.text",
    item1: "USD cents, EUR cents",
    item2: "100 USD",
    item3: "1000 USD",
    item4: "0",
    item5: "0.03%-0.08%",
    item6: "1:1 -1:1000",
    item7: "25%",
    item8: "30%",
    item9: "10%",
    item10: "accounts.instant",
    opacity: false,
  },
];

const Second = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const [my_swiper, set_my_swiper] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={classes.second}>
      <div className={[classes.secondBody, "mb mt"].join(" ")}>
        <div className="container">
          <div className={classes.secondTop}>
            <h2 className={[classes.secondTitle, "font-64"].join(" ")}>
              {t('accounts.title')}
            </h2>
            <p className={[classes.secondSubtitle, "font-20"].join(" ")}>
              {t('accounts.text')}
            </p>
          </div>

          <div className={classes.secondSlider}>
            <Swiper
              onInit={(ev) => set_my_swiper(ev)}
              modules={[Pagination]}
              pagination={{ clickable: true }}
              slidesPerView={3}
              spaceBetween={20}
              breakpoints={{
                1650: {
                  slidesPerView: 3,
                },
                481: {
                  slidesPerView: 3,
                },
                100: {
                  slidesPerView: 1,
                },
              }}
            >
              {content.map((i, index) => {
                if (i.opacity && width > 480) {
                  return <SwiperSlide key={index}></SwiperSlide>;
                }
                if (!i.opacity) {
                  return (
                    <SwiperSlide key={i.id}>
                      <div className={classes.slide}>
                        <div className={classes.slideSlideItem}>
                          <h3 className={[classes.slideSlideItemTitle, "font-32"].join(" ")}>
                            {i.title}
                          </h3>
                          <p className={[classes.slideSlideItemSubtitle, "font-20"].join(" ")}>
                            {t(i.text)}
                          </p>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_01')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item1}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_02')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item2}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_03')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {t(i.item3)}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_04')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item4}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_05')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item5}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_06')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item6}
                            </p>
                          </div>
                          <div
                            className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_07')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item7}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_08')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item8}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_09')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {i.item9}
                            </p>
                          </div>
                          <div className={`${classes.slideSlideItemRow} font-20`}>
                            <p className={classes.slideSlideItemRowLeft}>
                              {t('accounts.row_10')}
                            </p>
                            <p className={classes.slideSlideItemRowRight}>
                              {t(i.item10)}
                            </p>
                          </div>
                          <div className={classes.slideSlideItemBtn}>
                            <Button>
                              {t('btn.open_account')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                }
                return null;
              })}
            </Swiper>
          </div>
          <div className={classes.secondWrappperArrows}>
            <div className={classes.secondArrows}>
              <div
                className={classes.secondArrowsLeft}
                onClick={() => my_swiper.slidePrev()}
              >
                <img alt={"Details"} src={arrow} />
              </div>
              <div
                className={classes.secondArrowsRight}
                onClick={() => my_swiper.slideNext()}
              >
                <img alt={"Details"} src={arrow} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
