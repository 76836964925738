import React, { useEffect, useState } from "react";
import classes from './MeetOur.module.scss';
import { Trans, useTranslation } from "react-i18next";
import $ from 'jquery';

const cols = [
    {
        row_01: '',
        row_02: 'home.meet_our.row_title_01',
        row_03: 'home.meet_our.row_title_02',
        row_04: 'home.meet_our.row_title_03',
    },
    {
        row_01: 'Standart',
        row_02: 'EUR, USD, RUR',
        row_03: '1 USD',
        row_04: 'home.meet_our.no_limits',
    },
    {
        row_01: 'Eurica',
        row_02: 'EUR, USD, RUR',
        row_03: '1 USD',
        row_04: 'home.meet_our.no_limits',
    },
    {
        row_01: 'Cent Standart',
        row_02: 'USD cents, EUR cents',
        row_03: '1 USD',
        row_04: '1000 USD',
    },
    {
        row_01: 'Cent Eurica',
        row_02: 'USD cents, EUR cents',
        row_03: '1 USD',
        row_04: '1000 USD',
    },
];

const MeetOur = () => {
    const { t } = useTranslation();
    const [ domLoaded, setDomLoaded ] = useState(false);
    useEffect(() => {
        if (domLoaded) {
            if (document.documentElement.clientWidth > 1220) {
                $(`#homeMeetOurBtnText_01`).animate({width: "toggle"}, 0);
                $(`#homeMeetOurBtnText_02`).animate({width: "toggle"}, 0);
                $(`#homeMeetOurBtnText_03`).animate({width: "toggle"}, 0);
                $(`#homeMeetOurBtnText_04`).animate({width: "toggle"}, 0);
            }
        }
    }, [domLoaded]);
    useEffect(() => {
        setDomLoaded(true);
    }, []);
    return (
        <section className={[classes.meetOur, 'mt'].join(' ')}>
            <div className="container">
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('home.meet_our.title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('home.meet_our.text')}
                </p>
            </div>
            <div className={[classes.container, 'container'].join(' ')}>
                <div className={classes.contentWr}>
                    <div className={classes.content}>
                        {cols.map((col, index) =>
                            <div 
                                id={`homeMeetOurCol_0${index}`} 
                                key={index} 
                                className={classes.col}
                                onMouseEnter={() => {
                                    if (document.documentElement.clientWidth > 1220) {
                                        $(`#homeMeetOurBtnText_0${index}`).stop().animate({
                                            width: "toggle",
                                        }, 300);
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (document.documentElement.clientWidth > 1220) {
                                        $(`#homeMeetOurBtnText_0${index}`).stop().animate({
                                            width: "toggle",
                                        }, 300);
                                    }
                                }}
                            >
                                <div className={[classes.row, classes.rowTop].join(' ')}>
                                    {col.row_01.length > 0 &&
                                        <Trans components={{ span: <span/>, bold: <strong/> }}>
                                            <p>
                                                {col.row_01}
                                            </p>
                                        </Trans>
                                    }
                                </div>
                                <div className={[classes.row, classes.rowCenter, classes.rowCenterBorder, 'font-20'].join(' ')}>
                                    <p className={classes.rowCenterPar}>
                                        {t(col.row_02)}
                                    </p>
                                </div>
                                <div className={[classes.row, classes.rowCenter, classes.rowCenterBorder, 'font-20'].join(' ')}>
                                    <p className={classes.rowCenterPar}>
                                        {t(col.row_03)}
                                    </p>
                                </div>
                                <div className={[classes.row, classes.rowCenter, 'font-20'].join(' ')}>
                                    <p className={classes.rowCenterPar}>
                                        {t(col.row_04)}
                                    </p>
                                </div>
                                <div className={[classes.row, classes.rowBottom].join(' ')}>
                                    <a id={`homeMeetOurBtn_0${index}`} href="#" className={classes.btn}>
                                        <span id={`homeMeetOurBtnText_0${index}`} className={[classes.btnText, 'font-20'].join(' ')}>
                                            <span>
                                                {t('btn.see_more')}
                                            </span>
                                        </span>
                                        <svg className={classes.btnArrow} width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 15L9 7.78788L1 1" stroke="#587291" strokeWidth="1.2"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MeetOur;
