import React from "react";
import classes from "./Five.module.scss";
import img1 from "../../../assets/img/trading/5/1.webp";
import { useTranslation } from "react-i18next";

const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.five, ""].join(" ")}>
      <div className={[classes.fiveBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fiveWrapper, ""].join(" ")}>
            <div className={[classes.fiveTitle, "font-48"].join(" ")}>
              {t('conditions.support.title')}
            </div>
            <div className={[classes.fiveSubtitle, "font-20"].join(" ")}>
              {t('conditions.support.text')}
            </div>
            <div className={[classes.fiveImg, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
