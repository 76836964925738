import React from "react";
import classes from './Banner.module.scss';
import image_01 from '../../../assets/img/AboutPage/Banner/image_01.webp';
import image_02 from '../../../assets/img/AboutPage/Banner/image_02.webp';
import light from '../../../assets/img/AboutPage/Banner/light.webp';
import light_mob from '../../../assets/img/AboutPage/Banner/light_mob.webp';
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.banner}>
            <img className={classes.light} src={light} alt='' />
            <img className={classes.light_mob} src={light_mob} alt='' />
            <div className="container">
                <div className={classes.welcomeBonus}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-64'].join(' ')}>
                            {t('about.banner.title')}
                        </h2>
                        <div className={[classes.text, 'font-20'].join(' ')}>
                            <p>
                                {t('about.banner.text_01')}
                            </p>
                            <p>
                                {t('about.banner.text_02')}
                            </p>
                            <p>
                                {t('about.banner.text_03')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.image}>
                        <img src={image_01} alt='' />
                    </div>
                </div>
                <div className={[classes.welcomeBonus, classes.welcomeBonus_02].join(' ')}>
                    <div className={classes.image}>
                        <img src={image_02} alt='' />
                    </div>
                    <div className={classes.content}>
                        <div className={[classes.text, 'font-20'].join(' ')}>
                            <p>
                                {t('about.banner.text_04')}
                            </p>
                            <p>
                                {t('about.banner.text_05')}
                            </p>
                            <p>
                                {t('about.banner.text_06')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
