import React, { useState } from "react";
import classes from "./Three.module.scss";
import img11 from "../../../assets/img/trading/3/1/1.webp";
import img12 from "../../../assets/img/trading/3/1/2.png";
import img13 from "../../../assets/img/trading/3/1/3.png";
import img14 from "../../../assets/img/trading/3/1/4.png";
import img21 from "../../../assets/img/trading/3/2/1.webp";
import img31 from "../../../assets/img/trading/3/3/1.webp";
import img32 from "../../../assets/img/trading/3/3/2.png";
import img33 from "../../../assets/img/trading/3/3/3.png";
import img34 from "../../../assets/img/trading/3/3/4.png";
import img41 from "../../../assets/img/trading/3/4/1.webp";
import img51 from "../../../assets/img/trading/3/5/1.webp";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  const [count, setCount] = useState(1);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      text: "conditions.tabs.title_01",
      active: true,
    },
    {
      id: 2,
      text: "conditions.tabs.title_02",
      active: false,
    },
    {
      id: 3,
      text: "conditions.tabs.title_03",
      active: false,
    },
    {
      id: 4,
      text: "conditions.tabs.title_04",
      active: false,
    },
    {
      id: 5,
      text: "conditions.tabs.title_05",
      active: false,
    },
  ]);

  return (
    <div className={[classes.three, "mt mb"].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.threeTitle, "font-48"].join(" ")}>
            {t('conditions.tabs.title')}
          </h2>
          <div className={[classes.threeTop, ""].join(" ")}>
            {tabs.map((i) => {
              return (
                <p
                  key={i.id}
                  onClick={() => {
                    setCount(i.id);
                    setTabs(tabs.map((subI) => i.id === subI.id
                      ? { ...subI, active: true }
                      : { ...subI, active: false }
                    ));
                  }}
                  className={i.active
                    ? [classes.threeTopTab, classes.active, "font-32"].join(" ")
                    : [classes.threeTopTab, "font-32"].join(" ")
                  }
                >
                  {t(i.text)}
                </p>
              );
            })}
          </div>
          {count === 1 && (
            <div className={[classes.threeContentTab, ""].join(" ")}>
              <div className={[classes.threeRow, ""].join(" ")}>
                <div className={[classes.threeRowLeft, ""].join(" ")}>
                  <h3
                    className={[classes.threeRowLeftTitle, "font-24"].join(" ")}
                  >
                    {t('conditions.tabs.title_01')}
                  </h3>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_01.text_01')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_01.text_02')}
                  </p>
                  <div className={[classes.threeRowLeftIcons, ""].join(" ")}>
                    <div
                      className={[classes.threeRowLeftIconsItem, ""].join(" ")}
                    >
                      <div
                        className={[classes.threeRowLeftIconsItemImg, ""].join(
                          " "
                        )}
                      >
                        <img src={img12} alt="" />
                      </div>
                      <div
                        className={[
                          classes.threeRowLeftIconsItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        Bitcoin
                      </div>
                    </div>
                    <div
                      className={[classes.threeRowLeftIconsItem, ""].join(" ")}
                    >
                      <div
                        className={[classes.threeRowLeftIconsItemImg, ""].join(
                          " "
                        )}
                      >
                        <img src={img13} alt="" />
                      </div>
                      <div
                        className={[
                          classes.threeRowLeftIconsItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        Ethereum
                      </div>
                    </div>
                    <div
                      className={[classes.threeRowLeftIconsItem, ""].join(" ")}
                    >
                      <div
                        className={[classes.threeRowLeftIconsItemImg, ""].join(
                          " "
                        )}
                      >
                        <img src={img14} alt="" />
                      </div>
                      <div
                        className={[
                          classes.threeRowLeftIconsItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        Ripple
                      </div>
                    </div>
                  </div>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_01.text_03')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_01.text_04')}
                  </p>
                  <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                    <Button>
                      {t('btn.start_trading')}
                    </Button>
                  </div>
                </div>
                <div className={[classes.threeRowRight, ""].join(" ")}>
                  <img alt="" src={img11} />
                </div>
              </div>
            </div>
          )}
          {count === 2 && (
            <div className={[classes.threeContentTab, ""].join(" ")}>
              <div className={[classes.threeRow, ""].join(" ")}>
                <div className={[classes.threeRowLeft, ""].join(" ")}>
                  <h3
                    className={[classes.threeRowLeftTitle, "font-24"].join(" ")}
                  >
                    {t('conditions.tabs.title_02')}
                  </h3>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_02.text_01')}
                  </p>
                  <div className={[classes.threeRowLeftList, ""].join(" ")}>
                    <ul
                      className={[classes.threeRowLeftListLeft, ""].join(" ")}
                    >
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        NYSE
                      </li>
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        NASDAQ
                      </li>
                    </ul>
                    <ul
                      className={[classes.threeRowLeftListRight, ""].join(" ")}
                    >
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        Apollo Tyres
                      </li>
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        Dalmia Bharat
                      </li>
                    </ul>
                  </div>

                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_02.text_02')}
                  </p>

                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_02.text_03')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_02.text_04')}
                  </p>
                  <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                    <Button>
                      {t('btn.start_trading')}
                    </Button>
                  </div>
                </div>
                <div className={[classes.threeRowRight, ""].join(" ")}>
                  <img alt="" src={img21} />
                </div>
              </div>
            </div>
          )}
          {count === 3 && (
            <div className={[classes.threeContentTab, ""].join(" ")}>
              <div className={[classes.threeRow, ""].join(" ")}>
                <div className={[classes.threeRowLeft, ""].join(" ")}>
                  <h3
                    className={[classes.threeRowLeftTitle, "font-24"].join(" ")}
                  >
                    {t('conditions.tabs.title_03')}
                  </h3>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_03.text_01')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_03.text_02')}
                  </p>
                  <div className={[classes.threeRowLeftCurrency, ""].join(" ")}>
                    <div
                      className={[classes.threeRowLeftCurrencyItem, ""].join(
                        " "
                      )}
                    >
                      <img alt="" src={img32} />
                      <div
                        className={[
                          classes.threeRowLeftCurrencyItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        EUR/USD
                      </div>
                    </div>
                    <div
                      className={[classes.threeRowLeftCurrencyItem, ""].join(
                        " "
                      )}
                    >
                      <img alt="" src={img33} />
                      <div
                        className={[
                          classes.threeRowLeftCurrencyItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        GBP/USD
                      </div>
                    </div>
                    <div
                      className={[classes.threeRowLeftCurrencyItem, ""].join(
                        " "
                      )}
                    >
                      <img alt="" src={img34} />
                      <div
                        className={[
                          classes.threeRowLeftCurrencyItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        USD/JPY etc.
                      </div>
                    </div>
                  </div>

                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_03.text_03')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_03.text_04')}
                  </p>
                  <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                    <Button>
                      {t('btn.start_trading')}
                    </Button>
                  </div>
                </div>
                <div className={[classes.threeRowRight, ""].join(" ")}>
                  <img alt="" src={img31} />
                </div>
              </div>
            </div>
          )}
          {count === 4 && (
            <div className={[classes.threeContentTab, ""].join(" ")}>
              <div className={[classes.threeRow, ""].join(" ")}>
                <div className={[classes.threeRowLeft, ""].join(" ")}>
                  <h3
                    className={[classes.threeRowLeftTitle, "font-24"].join(" ")}
                  >
                    {t('conditions.tabs.title_04')}
                  </h3>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_04.text_01')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_04.text_02')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_04.text_03')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_04.text_04')}
                  </p>
                  <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                    <Button>
                      {t('btn.start_trading')}
                    </Button>
                  </div>
                </div>
                <div className={[classes.threeRowRight, ""].join(" ")}>
                  <img alt="" src={img41} />
                </div>
              </div>
            </div>
          )}
          {count === 5 && (
            <div className={[classes.threeContentTab, ""].join(" ")}>
              <div className={[classes.threeRow, ""].join(" ")}>
                <div className={[classes.threeRowLeft, ""].join(" ")}>
                  <h3
                    className={[classes.threeRowLeftTitle, "font-24"].join(" ")}
                  >
                    {t('conditions.tabs.title_05')}
                  </h3>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_05.text_01')}
                  </p>
                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_05.text_02')}
                  </p>
                  <div className={[classes.threeRowLeftList, ""].join(" ")}>
                    <ul
                      className={[classes.threeRowLeftListLeft, ""].join(" ")}
                    >
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        S&P 500
                      </li>
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        Dow Jones Industrial Average
                      </li>
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        NASDAQ
                      </li>
                    </ul>
                    <ul
                      className={[classes.threeRowLeftListRight, ""].join(" ")}
                    >
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        FTSE 100
                      </li>
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        DAX
                      </li>
                      <li
                        className={[
                          classes.threeRowLeftListItem,
                          "font-20",
                        ].join(" ")}
                      >
                        Nikkei 225
                      </li>
                    </ul>
                  </div>

                  <p
                    className={[classes.threeRowLeftText, "font-20"].join(" ")}
                  >
                    {t('conditions.tabs.tab_05.text_03')}
                  </p>

                  <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                    <Button>
                      {t('btn.start_trading')}
                    </Button>
                  </div>
                </div>
                <div className={[classes.threeRowRight, ""].join(" ")}>
                  <img alt="" src={img51} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Three;
