import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import image from '../../../assets/img/PromotionsPage/Banner/image.webp';
import light from '../../../assets/img/PromotionsPage/Banner/light.webp';
import light_mob from '../../../assets/img/PromotionsPage/Banner/light_mob.webp';
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const contentRef = useRef(null);
    useEffect(() => {
        gsap.fromTo(imageRef.current, {
            y: 100,
            scale: 0.7,
            opacity: 0,
        }, {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
        gsap.fromTo(contentRef.current, {
            y: 150,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
    });
    return (
        <section className={classes.banner}>
            <img className={classes.light} src={light} alt='' />
            <img className={classes.light_mob} src={light_mob} alt='' />
            <div className="container">
                <div className={classes.bannerBody}>
                    <div ref={contentRef} className={classes.content}>
                        <h1 className={[classes.title, 'font-64'].join(' ')}>
                            {t('promotions.banner.title')}
                        </h1>
                        <div className={classes.textContent}>
                            <p className={[classes.text, 'font-20'].join(' ')}>
                                {t('promotions.banner.text_01')}
                            </p>
                            <h4 className={[classes.subtext, 'font-20'].join(' ')}>
                                {t('promotions.banner.text_02')}
                            </h4>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.start_trading')}
                            </Button>
                        </div>
                    </div>
                    <img ref={imageRef} className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;
