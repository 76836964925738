import React from "react";
import classes from "./Button.module.scss";
import { useParams } from "react-router-dom";

const Button = ({ children, login }) => {
  const { lang } = useParams();
  return (
    <a
      href={`${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`}
      className={classes.button}
    >
      <span>
        {children}
      </span>
    </a>
  );
};

export default Button;
