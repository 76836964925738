import logo from '../assets/img/logo.svg';
import image_01 from '../assets/img/HomePage/Banner/icon_01.webp';
import image_02 from '../assets/img/HomePage/Banner/icon_02.webp';
import image_03 from '../assets/img/HomePage/Banner/icon_03.webp';
import image_04 from '../assets/img/HomePage/Banner/icon_04.webp';
import image_05 from '../assets/img/HomePage/Banner/icon_05.webp';
import image_06 from '../assets/img/HomePage/Banner/icon_06.webp';
import image_07 from '../assets/img/HomePage/Banner/icon_07.webp';
import image_08 from '../assets/img/HomePage/Banner/image.webp';
import image_09 from '../assets/img/HomePage/Banner/light.webp';

export const images = [
    logo,
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
    image_06,
    image_07,
    image_08,
    image_09,
];
