import React, { useEffect, useRef, useState } from "react";
import classes from "./Four.module.scss";
import img1 from "../../../assets/img/trading/4/2.webp";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Four = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const col2 = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col2.current, {
        scale: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, [width]);

  return (
    <div className={[classes.four, "mb"].join(" ")} ref={col1}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowLeft, ""].join(" ")}>
              <h2 className={[classes.fourRowLeftTitle, "font-48"].join(" ")}>
                {t('conditions.platform.title')}
              </h2>
              <p className={[classes.fourRowLeftText, "font-20"].join(" ")}>
                {t('conditions.platform.text')}
              </p>
              <div
                className={[classes.fourRowLeftListTitle, "font-32"].join(" ")}
              ></div>
              <div className={[classes.fourRowLeftList, ""].join(" ")}>
                <ul className={[classes.fourRowLeftListLeft, ""].join(" ")}>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_01')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_02')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_03')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_04')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_05')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_06')}
                  </li>
                </ul>
                <ul className={[classes.fourRowLeftListRight, ""].join(" ")}>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_07')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_08')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_09')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_10')}
                  </li>
                  <li className={[classes.fourRowLeftListItem, "font-20"].join(" ")}>
                    {t('conditions.platform.li_11')}
                  </li>
                </ul>
              </div>
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")} ref={col2}>
              <img alt="" src={img1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
