import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Five from "../components/TradingComponents/Five/Five";
import Most from "../components/FaqComponents/Most/Most";

const FaqPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers L.P. · FAQ</title>
        <meta name="title" content="BGC Brokers L.P. Trading Platform - Frequently Asked Questions (FAQ)" />
        <meta property="og:title" content="BGC Brokers L.P. Trading Platform - Frequently Asked Questions (FAQ)" />
        <meta property="twitter:title" content="BGC Brokers L.P. Trading Platform - Frequently Asked Questions (FAQ)" />

        <meta name="description" content="Learn more about BGC Brokers L.P. Trading Platform's features, benefits, and policies. Find answers to the most commonly asked questions from our FAQ page. Start trading with confidence today." />
        <meta property="og:description" content="Learn more about BGC Brokers L.P. Trading Platform's features, benefits, and policies. Find answers to the most commonly asked questions from our FAQ page. Start trading with confidence today." />
        <meta property="twitter:description" content="Learn more about BGC Brokers L.P. Trading Platform's features, benefits, and policies. Find answers to the most commonly asked questions from our FAQ page. Start trading with confidence today." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Most />
        <Five />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;
