import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import First from "../components/TradingComponents/First/First";
import Second from "../components/TradingComponents/Second/Second";
import Three from "../components/TradingComponents/Three/Three";
import Four from "../components/TradingComponents/Four/Four";
import Five from "../components/TradingComponents/Five/Five";

const ConditionsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers L.P. · Trading Conditions</title>
        <meta name="title" content="Trading Conditions at BGC Brokers L.P. - Competitive Spreads and High Leverage" />
        <meta property="og:title" content="Trading Conditions at BGC Brokers L.P. - Competitive Spreads and High Leverage" />
        <meta property="twitter:title" content="Trading Conditions at BGC Brokers L.P. - Competitive Spreads and High Leverage" />

        <meta name="description" content="BGC Brokers L.P. offers competitive trading conditions for traders of all levels. Enjoy tight spreads, high leverage, and fast execution on Forex, stocks, cryptocurrencies, and more. Open an account now and trade with confidence." />
        <meta property="og:description" content="BGC Brokers L.P. offers competitive trading conditions for traders of all levels. Enjoy tight spreads, high leverage, and fast execution on Forex, stocks, cryptocurrencies, and more. Open an account now and trade with confidence." />
        <meta property="twitter:description" content="BGC Brokers L.P. offers competitive trading conditions for traders of all levels. Enjoy tight spreads, high leverage, and fast execution on Forex, stocks, cryptocurrencies, and more. Open an account now and trade with confidence." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <Three />
        <Four />
        <Five />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ConditionsPage;
