import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import icon_01 from '../../../assets/img/HomePage/Banner/icon_01.webp';
import icon_02 from '../../../assets/img/HomePage/Banner/icon_02.webp';
import icon_03 from '../../../assets/img/HomePage/Banner/icon_03.webp';
import icon_04 from '../../../assets/img/HomePage/Banner/icon_04.webp';
import icon_05 from '../../../assets/img/HomePage/Banner/icon_05.webp';
import icon_06 from '../../../assets/img/HomePage/Banner/icon_06.webp';
import icon_07 from '../../../assets/img/HomePage/Banner/icon_07.webp';
import image from '../../../assets/img/HomePage/Banner/image.webp';
import light from '../../../assets/img/HomePage/Banner/light.webp';
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const textRef = useRef(null);
    const subtextRef = useRef(null);
    const btnRef = useRef(null);
    const imageRef = useRef(null);
    const imageWrRef = useRef(null);
    const coinRef_01 = useRef(null);
    const coinRef_02 = useRef(null);
    const coinRef_03 = useRef(null);
    const coinRef_04 = useRef(null);
    const coinRef_05 = useRef(null);
    const coinRef_06 = useRef(null);
    const coinRef_07 = useRef(null);
    const coinWrRef_01 = useRef(null);
    const coinWrRef_02 = useRef(null);
    const coinWrRef_03 = useRef(null);
    const coinWrRef_04 = useRef(null);
    const coinWrRef_05 = useRef(null);
    const coinWrRef_06 = useRef(null);
    const coinWrRef_07 = useRef(null);
    useEffect(() => {
        gsap.fromTo(subtitleRef.current, {
            y: 50,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
        gsap.fromTo(titleRef.current, {
            y: 80,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
        gsap.fromTo(textRef.current, {
            y: 120,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
        gsap.fromTo(subtextRef.current, {
            y: 150,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
        gsap.fromTo(btnRef.current, {
            y: 180,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
        gsap.fromTo(imageRef.current, {
            scale: 0.9,
            opacity: 0,
        }, {
            scale: 1,
            opacity: 1,
            duration: .7,
            delay: 0.8,
        });
        gsap.fromTo(coinRef_01.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0,
        });
        gsap.fromTo(coinRef_02.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.1,
        });
        gsap.fromTo(coinRef_03.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.2,
        });
        gsap.fromTo(coinRef_04.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.3,
        });
        gsap.fromTo(coinRef_05.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.4,
        });
        gsap.fromTo(coinRef_06.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.5,
        });
        gsap.fromTo(coinRef_07.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.6,
        });

        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().to(coinWrRef_01.current, {
                    y: 100,
                    x: -60,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 200px", 
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(coinWrRef_02.current, {
                    y: 120,
                    x: -10,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 250px", 
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(coinWrRef_03.current, {
                    y: 150,
                    x: 20,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 250px", 
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(coinWrRef_04.current, {
                    y: 180,
                    x: 0,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 300px", 
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(coinWrRef_05.current, {
                    y: 220,
                    x: 50,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 250px", 
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(coinWrRef_06.current, {
                    y: 180,
                    x: -20,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 250px", 
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(coinWrRef_07.current, {
                    y: 180,
                    x: -100,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 250px", 
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(imageWrRef.current, {
                    scale: 0.9,
                    scrollTrigger: {
                        trigger: coinRef_01.current,
                        start: "top 250px", 
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={classes.banner}>
            <img className={classes.light} src={light}  alt=''/>
            <div className="container">
                <div className={classes.bannerBody}>
                    <h2 ref={subtitleRef} className={[classes.subtitle, 'font-40'].join(' ')}>
                        {t('home.banner.subtitle')}
                    </h2>
                    <h1 ref={titleRef} className={[classes.title, 'font-64'].join(' ')}>
                        <Trans>
                            {t('home.banner.title')}
                        </Trans>
                    </h1>
                    <p ref={textRef} className={[classes.text, 'font-20'].join(' ')}>
                        <Trans>
                            {t('home.banner.text')}
                        </Trans>
                    </p>
                    <h3 ref={subtextRef} className={[classes.subtext, 'font-24'].join(' ')}>
                        {t('home.banner.subtext')}
                    </h3>
                    <div ref={btnRef} className={classes.btn}>
                        <Button>
                            {t('btn.start_trading')}
                        </Button>
                    </div>
                    <div ref={imageWrRef}>
                        <img ref={imageRef} className={classes.image} src={image}  alt='' />
                    </div>
                    <div ref={coinWrRef_01} className={[classes.icon_01, classes.icon].join(' ')}>
                        <img ref={coinRef_01} src={icon_01} alt='' />
                    </div>
                    <div ref={coinWrRef_02} className={[classes.icon_02, classes.icon].join(' ')}>
                        <img ref={coinRef_02} src={icon_02} alt='' />
                    </div>
                    <div ref={coinWrRef_03} className={[classes.icon_03, classes.icon].join(' ')}>
                        <img ref={coinRef_03} src={icon_03} alt='' />
                    </div>
                    <div ref={coinWrRef_04} className={[classes.icon_04, classes.icon].join(' ')}>
                        <img ref={coinRef_04} src={icon_04} alt='' />
                    </div>
                    <div ref={coinWrRef_05} className={[classes.icon_05, classes.icon].join(' ')}>
                        <img ref={coinRef_05} src={icon_05} alt='' />
                    </div>
                    <div ref={coinWrRef_06} className={[classes.icon_06, classes.icon].join(' ')}>
                        <img ref={coinRef_06} src={icon_06} alt='' />
                    </div>
                    <div ref={coinWrRef_07} className={[classes.icon_07, classes.icon].join(' ')}>
                        <img ref={coinRef_07} src={icon_07} alt='' />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
