import React, { useState, useEffect, useRef } from "react";
import classes from './TheSupport.module.scss';
import robot from '../../../assets/img/HomePage/TheSupport/robot.webp';
import icons from '../../../assets/img/HomePage/TheSupport/icons.webp';
import $ from 'jquery';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const TheSupport = () => {
    const { t } = useTranslation();
    const [domLoaded, setDomLoaded] = useState(false);
    const ref1 = useRef(null);
    const robotRef = useRef(null);
    const iconsRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(robotRef.current, {
                    y: 150,
                    scale: 0.7,
                }, {
                    y: 0,
                    scale: 1,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(iconsRef.current, {
                    y: 0,
                    scale: 0.6,
                }, {
                    y: 0,
                    scale: 1,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    useEffect(() => {
        if (domLoaded) {
            if (document.documentElement.clientWidth > 1220) {
                $(ref1.current).animate({ width: "toggle" }, 0);
            }
        }
    }, [domLoaded]);
    useEffect(() => {
        setDomLoaded(true);
    }, []);
    return (
        <section className="container mt">
            <div ref={bodyRef} className={classes.theSupport}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        {t('home.the_support.title')}
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            <Trans>
                                {t('home.the_support.text_01')}
                            </Trans>
                        </p>
                        <p>
                            {t('home.the_support.text_02')}
                        </p>
                    </div>
                </div>
                <img ref={robotRef} className={classes.robot} src={robot} alt='' />
                <img ref={iconsRef} className={classes.icons} src={icons} alt='' />
            </div>
        </section>
    );
};

export default TheSupport;
