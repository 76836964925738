import React, { useEffect, useRef } from "react";
import classes from './BonusOnEach.module.scss';
import image from '../../../assets/img/PromotionsPage/Blocks/image_02.webp';
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const BonusOnEach = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const imageRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(imageRef.current, {
                    y: 50,
                    scale: 0.8,
                }, {
                    y: 0,
                    scale: 1,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=500px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container mt">
            <div ref={bodyRef} className={classes.welcomeBonus}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        {t('promotions.bonus.title')}
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('promotions.bonus.text_01')}
                        </p>
                        <p>
                            {t('promotions.bonus.text_02')}
                        </p>
                    </div>
                    <ul className="font-20">
                        <li>
                            <Trans>
                                {t('promotions.bonus.li_01')}
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                {t('promotions.bonus.li_02')}
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                {t('promotions.bonus.li_03')}
                            </Trans>
                        </li>
                    </ul>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.get_bonus')}
                        </Button>
                    </div>
                </div>
                <div className={classes.image}>
                    <img ref={imageRef} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default BonusOnEach;
