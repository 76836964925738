import React, { useEffect, useRef } from "react";
import classes from './HowToGet.module.scss';
import image_01 from '../../../assets/img/PromotionsPage/HowToGet/image_01.webp';
import image_02 from '../../../assets/img/PromotionsPage/HowToGet/image_02.webp';
import image_03 from '../../../assets/img/PromotionsPage/HowToGet/image_03.webp';
import image_04 from '../../../assets/img/PromotionsPage/HowToGet/image_04.webp';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const HowToGet = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const bodyRef = useRef(null);
    const cards = [
        {
            id: 1,
            ref: cardRef_01,
            image: image_01,
            title: 'promotions.how_get.card_01.title',
            text: 'promotions.how_get.card_01.text',
        },
        {
            id: 2,
            ref: cardRef_02,
            image: image_02,
            title: 'promotions.how_get.card_02.title',
            text: 'promotions.how_get.card_02.text',
        },
        {
            id: 3,
            ref: cardRef_03,
            image: image_03,
            title: 'promotions.how_get.card_03.title',
            text: 'promotions.how_get.card_03.text',
        },
        {
            id: 4,
            ref: cardRef_04,
            image: image_04,
            title: 'promotions.how_get.card_04.title',
            text: 'promotions.how_get.card_04.text',
        },
    ];
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(cardRef_01.current, {
                    x: -100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_01.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_02.current, {
                    x: 100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_01.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_03.current, {
                    x: -100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_03.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_04.current, {
                    x: 100,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardRef_03.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={[classes.howToGet, 'mt'].join(' ')}>
            <div className="container">
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('promotions.how_get.title')}
                </h2>
            </div>
            <div ref={bodyRef} className={[classes.container, 'container'].join(' ')}>
                <div className={classes.content}>
                    <div className={classes.cards}>
                        {cards.map((card) =>
                            <div ref={card.ref} key={card.id} className={classes.card}>
                                <div className={classes.cardNum}>
                                    <p>
                                        0{card.id}
                                    </p>
                                </div>
                                <div className={classes.cardImage}>
                                    <img src={card.image} alt='' />
                                </div>
                                <div className={classes.cardContent}>
                                    <h3 className={classes.cardTitle}>
                                        {t(card.title)}
                                    </h3>
                                    <p className={[classes.cardText, 'font-20'].join(' ')}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.swiperWr}>
                        <Swiper 
                            modules={[Pagination]}
                            className="howToGetSwiper"
                            spaceBetween={20}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                        >
                            {cards.map((card) =>
                                <SwiperSlide key={card.id} className="howToGetSwiperSlide">
                                    <div className={classes.card}>
                                        <div className={classes.cardNum}>
                                            <p>
                                                0{card.id}
                                            </p>
                                        </div>
                                        <div className={classes.cardImage}>
                                            <img src={card.image} alt='' />
                                        </div>
                                        <div className={classes.cardContent}>
                                            <h3 className={classes.cardTitle}>
                                                {t(card.title)}
                                            </h3>
                                            <p className={[classes.cardText, 'font-20'].join(' ')}>
                                                {t(card.text)}
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowToGet;
